import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import {
  Button, Col, Container, Form, Row, Tab
} from "react-bootstrap";
import { updateNewsData, userInfo } from "../../helper/service";
import Loader from "../../Loader";
import Header from "../Common/Header";
import SunRichTextEditor from "../RichTextEditor/SunRichTextEditor";
import NewSidebar from "../new-sidebar";
import Membershipsdeliverable from "./Membershipsdeliverable";
import Sidebar from "./Sidebar";
import SuccessPopup from "./Sucesspopup";

const MAX_NEWS_DATA_LENGTH = 1000;

const NewsSection = () => {
  const [userData, setUserData] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState("");
  const [password, setPassword] = useState("");
  const [newsData, setNewsData] = useState("");
  const [limitExceedError, setLimitExceedError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      const userId = localStorage.getItem("user_id");
      const data = await userInfo(userId);
      setEmail(data.result.validUser.email);
      setPassword(data.result.validUser.password);
      setPhoto(data.result.validUser.logo);
      setNewsData(data.result.validUser.newsData);
      setIsLoader(false);
    };
    getUserData();
  }, []);

  const cancel = async () => {
    setIsLoader(true);
    const userId = localStorage.getItem("user_id");
    const data = await userInfo(userId);
    setEmail(data.result.validUser.email);
    setPassword(data.result.validUser.password);
    setPhoto(data.result.validUser.logo);
    setNewsData(data.result.validUser.newsData);
    setIsLoader(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === "newsData") {
      if (limitExceeds(value)) {
        setLimitExceedError(true);
        return;
      }
      setLimitExceedError(false);
    }
    setNewsData(value);
  };

  const limitExceeds = (str) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(str, "text/html");
    const text = htmlDoc.body.textContent || "";
    return text.length > MAX_NEWS_DATA_LENGTH;
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setIsLoader(true);
    setIsSuccess(false);
    const userId = localStorage.getItem("user_id");
    const data = {
      newsData: newsData,
      userId: userId,
    };
    await updateNewsData(data);
    setIsSuccess(true);
    setIsLoader(false);
  };

  const handlSunRichtextEditorChange = (value) => {
    console.log("handle change", value);
    setNewsData(value);
  };

  return (
    <BodyClassName>
      <div className="wrapper">
        {isLoader ? <Loader /> : <></>}
        {isSuccess ? <SuccessPopup message="News Section Updated Successfully." /> : <></>}
        <Header />
        <NewSidebar />
        <div className="content-wrapper admin-membership-setting bg-white">
          <Membershipsdeliverable />
          <section>
            <Container fluid>
              <Tab.Container id="MembershipSettings" defaultActiveKey="NewsSectionSetting">
                <Row>
                  <Col xl={3}>
                    <Sidebar />
                  </Col>
                  <Col xl={8}>
                    <Tab.Content>
                      <Tab.Pane eventKey="NewsSectionSetting" className="WelcomePageSetting">
                        <div>
                          <h3 className="tab-title">Member News</h3>
                          <p className="tab-text">
                            News that will be displayed on member dashboard.
                          </p>
                          <form name="form" onSubmit={submitForm}>
                            <Row>
                              <Col md={12} style={{ height: 450 }}>
                                <Form.Group controlId="welcomeMessage">
                                  <Form.Label>
                                    Enter news to be displayed on member dashboard - Max 1000 characters
                                  </Form.Label>
                                  <SunRichTextEditor
                                    value={newsData}
                                    handlSunRichtextEditorChange={handlSunRichtextEditorChange}
                                  />
                                  {limitExceedError && (
                                    <Form.Label className="text-danger mt-2">
                                      1000 characters limit exceeded!
                                    </Form.Label>
                                  )}
                                </Form.Group>
                                <div className="d-flex justify-content-between flex-wrap">
                                  {/* <Button variant="custom" onClick={cancel}>Cancel</Button> */}
                                  <Button type="submit" variant="custom1">
                                    Save
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Container>
          </section>
        </div>
      </div>
    </BodyClassName>
  );
};

export default NewsSection;
