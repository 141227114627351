import React, { Component } from "react";
import { Nav, Image } from "react-bootstrap";
import rightArrow from "../../images/admin/right-arrow-white.svg";
import { NavLink } from "react-router-dom";

export default class Sidebar extends Component {
  render() {
    return (
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          {/* <Nav.Link eventKey="AccountDetails"><Image src={rightArrow} className="invisible mr-2"></Image> Account Details</Nav.Link> */}
          <NavLink to="/settings/account" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> Account
            Details
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          {/* <Nav.Link eventKey="CompanyDetails"><Image src={rightArrow} className="invisible mr-2"></Image> Company Details</Nav.Link> */}
          <NavLink to="/settings/company" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> Company
            Details
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          {/* <Nav.Link eventKey="PaymentSettings"><Image src={rightArrow} className="invisible mr-2"></Image> Payment Settings</Nav.Link> */}
          <NavLink to="/settings/payment" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> Payment
            Settings
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          {/* <Nav.Link eventKey="Sales Page"><Image src={rightArrow} className="invisible mr-2"></Image> Sales Page</Nav.Link> */}
          <NavLink to="/settings/sales" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> Sales
            Page
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          {/* <Nav.Link eventKey="NotificationsSettings"><Image src={rightArrow} className="invisible mr-2"></Image> Notifications Settings</Nav.Link> */}
          <NavLink to="/settings/notifications" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image>{" "}
            Notifications Settings
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          {/* <Nav.Link eventKey="NotificationsSettings"><Image src={rightArrow} className="invisible mr-2"></Image> Notifications Settings</Nav.Link> */}
          <NavLink to="/settings/change-password" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> Change
            Password
          </NavLink>
        </Nav.Item>

        {/* <Nav.Item>  */}
        {/* <Nav.Link eventKey="GeneralSettings"><Image src={rightArrow} className="invisible mr-2"></Image> General Settings</Nav.Link> */}
        {/* <NavLink to="/general-settings" className="nav-link"><Image src={rightArrow} className="invisible mr-2"></Image> General Settings</NavLink> */}
        {/* </Nav.Item> */}
        <Nav.Item>
          {/* <Nav.Link eventKey="WelcomePage"><Image src={rightArrow} className="invisible mr-2"></Image> Welcome Page</Nav.Link> */}
          <NavLink to="/settings/welcome" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> Welcome
            Page
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          {/* <Nav.Link eventKey="WelcomePage"><Image src={rightArrow} className="invisible mr-2"></Image> Welcome Page</Nav.Link> */}
          <NavLink to="/settings/news" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> News
            Section
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          {/* <Nav.Link eventKey="PolicyPages"><Image src={rightArrow} className="invisible mr-2"></Image> T&C's / Policy Pages</Nav.Link> */}
          <NavLink to="/settings/policy-pages" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image> T&C's /
            Policy
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/settings/un-subscribe" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image>
            Unsubscribe Settings
          </NavLink>
          {/* <Nav.Link eventKey="Unsubscribe"><Image src={rightArrow} className="invisible mr-2"></Image> Un subscribe</Nav.Link> */}
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/settings/billing" className="nav-link">
            <Image src={rightArrow} className="invisible mr-2"></Image>
            Billing
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  }
}
